import React, { useEffect } from "react";


import ReactGA from "react-ga";
import { IntlProvider } from "react-intl";
import it from './locales/it.json'

import "./App.scss";
import "./styles/main.scss";
import HomePage from "./containers/home/home";


function App() {

  useEffect(() => {
    ReactGA.initialize("UA-59238164-13");
    ReactGA.pageview(window.location.pathname + window.location.search);

    ReactGA.ga("send", "pageview", "casehistory.antreem.com");

  });


  return (
    <IntlProvider messages={it} locale="it" onError={() => { }}>
      <HomePage />
    </IntlProvider>
  );
}

export default App;
