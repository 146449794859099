import React, { useState } from 'react';
import DownloadForm from '../download-form/download-form';
import ProjectItem from './project-item';

import projects from "../../data/projects";
import customers from "../../data/customers";
import CustomerItem from './customer-item';

import { ReactComponent as Logo } from '../../logoAntreem.svg';
import { BASE_URL } from '../../data/constants';

const HomePage = () => {

    return <>
        <header>
            <a href={`${BASE_URL}/it`}><Logo className="logo" /></a>
        </header>
        <main>
            <h1>Storie di innovazione</h1>
            <p className="subtitle">Scopri alcuni dei nostri progetti</p>
            <div className="container-fluid projectsContainer">
                <div className="row">
                    {projects.map((value, index) =>
                        <ProjectItem key={index} value={value} index={index} />)}
                </div>
            </div>

        </main>

        <footer>
            <div className="container-fluid">
                <div className="row row-cols-xs-4 row-cols-md-9">
                    {customers.map((customer, index) => <CustomerItem key={index} value={customer} index={index} />)}
                </div>
            </div>
        </footer>
    </>
}

export default HomePage;